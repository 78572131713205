import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => (
  <footer className="footer">
    <div className="footer-inner-container">
      <Container>
        <Row className="align-items-end">
          <Col sm={12} md={6}>
            <h3>The Little Layup</h3>
            <p>© {new Date().getFullYear()}, The Little Layup</p>
          </Col>
          <Col sml={12} md={6}>
            <p className="text-right">Designed by <a href="https://vanessabrewster.com/" target="_blank" rel="noopener noreferrer">Vanessa Brewster</a> built by <a href="https://srrycmpny.com/" target="_blank" rel="noopener noreferrer">Sorry Company</a></p>
          </Col>
        </Row>
      </Container>
    </div>
  </footer>
);

export default Footer;
