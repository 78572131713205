import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import RBNavbar from 'react-bootstrap/Navbar';
import RBNav from 'react-bootstrap/Nav';
import useDarkMode from '../components/use-darkmode';

const Navbar = ({ data, siteTitle }) => {
  // eslint-disable-next-line
  const [ theme, toggleTheme ] = useDarkMode();

  return (
    <>
      <Helmet>
        <body className={
            typeof window !== 'undefined' && window.localStorage.getItem('theme')
            ? window.localStorage.getItem('theme')
            : 'light'
          }
        />
      </Helmet>
      <RBNavbar variant="dark" expand="lg">
        <RBNavbar.Brand href="/">{siteTitle}</RBNavbar.Brand>
        <RBNavbar.Toggle aria-controls="basic-navbar-nav" />
        <RBNavbar.Collapse id="basic-navbar-nav">
          <RBNav className="ml-auto">
            <RBNav.Link
              href="#"
              onClick={toggleTheme}
            >
              <Img
                alt="Adjust Icon"
                fixed={data.adjustIcon.childImageSharp.fixed}
              />
            </RBNav.Link>
            <RBNav.Link href="/shop">Shop</RBNav.Link>
            <RBNav.Link href="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D">Contact</RBNav.Link>
          </RBNav>
        </RBNavbar.Collapse>
      </RBNavbar>
    </>
  )
};

Navbar.propTypes = {
  data: PropTypes.shape({
    adjustIcon: PropTypes.object.isRequired,
  }).isRequired,
  siteTitle: PropTypes.string.isRequired,
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        adjustIcon: file(relativePath: { eq: "adjust-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 12, height: 12) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        adjustIconDark: file(relativePath: { eq: "adjust-icon-dark.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 12, height: 12) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Navbar data={data} {...props} />}
  />
);
